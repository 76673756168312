// DrawComponent.js
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import SkylarkPolygonLayer from './skylarkPolygonLayer';
import { v4 as uuidv4 } from 'uuid';

async function initializeMapboxDraw(globalRefs, globalState) {

  let mapRef = globalRefs.mapRef;
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let drawRef = globalRefs.drawRef;
  let setIsLoading = globalState.setIsLoading;
  let selectedYearEditing = globalState.selectedYearEditing;
  let setIsLanduse = globalState.setIsLanduse;
  let setLoadedAmount = globalState.setLoadedAmount;

  let setHasCreatedNewPolygonInModify = globalState.setHasCreatedNewPolygonInModify;

  let updating = 0;
  let creating = 0;
  let deleting = 0;

  let selectedMetric = globalState.selectedMetric;
  let isDrawing = globalState.isDrawing;

  let setFinishedEditing = globalState.setFinishedEditing;
  
  if (isDrawing) {
    if (drawRef.current) {
      mapRef.current.removeControl(drawRef.current);
      drawRef.current = null;
    }
  }
  
  // Remove previous listeners if they exist
  if (drawRef.current && drawRef.current.listeners) {
    mapRef.current.off('draw.update', drawRef.current.listeners.updateHandler);
    mapRef.current.off('draw.create', drawRef.current.listeners.createHandler);
    mapRef.current.off('draw.delete', drawRef.current.listeners.deleteHandler);
  }
  
  // Initialize MapboxDraw only if it's not already initialized
  drawRef.current = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true,
    },
  });
  
  mapRef.current.addControl(drawRef.current);
  
  const geojson = longstandingGeoJSONRef.current;

  console.log(geojson)
  
  const updatedFeatures = geojson.features.map(feature => {
    return {
      ...feature,
      properties: {
        ...feature.properties,
        skylark_id: feature.skylark_id
      }
    };
  });
  
  const filteredFeatures = updatedFeatures.filter(feature => {
    if (feature.feature_data_standard.deleted <= selectedYearEditing) {
      console.log("deleted");
      return false;
    } else {
      if (feature.feature_data_simple_timeseries.BoundaryChange[selectedYearEditing] !== "000,000") {
        console.log("boundary changed");
        try {
          feature.geometry.coordinates = JSON.parse(feature.feature_data_simple_timeseries.BoundaryChange[selectedYearEditing]);
        } catch (error) {
          console.error("Failed to parse BoundaryChange coordinates:", error);
          return false;
        }
      }
      return true;
    }
  });
  
  // Create a new GeoJSON object with the filtered features
  const updatedGeojson = {
    type: "FeatureCollection",
    features: filteredFeatures
  };
  
  console.log(updatedGeojson);
  
  // Ensure the GeoJSON is valid before adding
  if (drawRef.current && updatedGeojson.features.length > 0) {
    drawRef.current.add(updatedGeojson);
  } else {
    console.error("No valid features to add to MapboxDraw.");
  }
  
  // const filteredFeatures = geojson.features.filter(feature => {
  //   // Check if the feature has an amended_year object and the selectedYearEditing is a key in that object
  //   if (feature.feature_data_simple_timeseries && feature.feature_data_simple_timeseries.carbon_density && feature.properties.amended_year && feature.properties.amended_year[selectedYearEditing]) {
  //     // console.log("this years amended landtype: " + feature.properties.UKHAB)
  //     // Now check if within that year there's a deletion amend type
  //     if (feature.properties.amended_year[selectedYearEditing].deletion) {
  //       // If so, access the amend_data within landtype_change and log it
  //       const amendData = feature.properties.amended_year[selectedYearEditing].deletion;
  //       if (amendData) {
  //         if (amendData.amend_data === "true") {
  //           console.log(feature)
  //           return false;
  //         }
  //       }
  //     }
  //     // Now check if within that year there's a shape_change amend type
  //     if (feature.properties.amended_year[selectedYearEditing].shape_change) {
  //       // If so, access the amend_data within landtype_change and log it
  //       const amendData = feature.properties.amended_year[selectedYearEditing].shape_change.amend_data;
  //       if (amendData) {
  //         if (!feature.properties.amended_year[selectedYearEditing].shape_original.amend_data) {
  //           return false;
  //         } else {
  //           feature.geometry.coordinates = amendData; // Assuming you want to update this property with the amend data
  //           console.log("this years amended coordinates");
  //           console.log(feature)
  //         }
  //       } else {
  //         feature.geometry.coordinates = feature.properties.amended_year[selectedYearEditing].shape_original.amend_data;
  //       }
  //     }
  //   } else {
  //     return false;
  //   }
  //   return true; // Keep all features, adjust as necessary for your use case
  // });

  // Define new listeners
  const updateListener = drawUpdateHandler(mapRef, longstandingGeoJSONRef, selectedYearEditing, updating, setFinishedEditing);
  const createListener = drawCreateHandler(mapRef, longstandingGeoJSONRef, selectedYearEditing, selectedMetric, creating, setHasCreatedNewPolygonInModify, setIsLanduse, setFinishedEditing);
  const deleteListener = drawDeleteHandler(mapRef, longstandingGeoJSONRef, selectedYearEditing, deleting, setFinishedEditing);

  // Store references to the new listeners in the drawRef for later removal
  drawRef.current.listeners = {
    updateHandler: updateListener,
    createHandler: createListener,
    deleteHandler: deleteListener
  };

  // Attach the new listeners to the map
  mapRef.current.on('draw.update', updateListener);
  mapRef.current.on('draw.create', createListener);
  mapRef.current.on('draw.delete', deleteListener);

  setIsLoading(false);
}

// Draw events

export const drawUpdateHandler = (mapRef, longstandingGeoJSONRef, selectedYearEditing, updating, setFinishedEditing) => (e) => {

  setFinishedEditing(false);

  console.log("updating")

  // Array of the years in chronological order
  const years = [
    "2024-01-01T00:00:00+00:00",
    "2027-01-01T00:00:00+00:00",
    "2029-01-01T00:00:00+00:00",
    "2031-01-01T00:00:00+00:00"
  ];

  let selectedYearEditingCatch = "";
  selectedYearEditingCatch = selectedYearEditing;
  // if (updating === 0) {
  console.log("updating inner")
  const updatedFeatures = e.features;
  if (updatedFeatures.length > 0) {
    updatedFeatures.forEach((updatedFeature) => {
      const skylarkId = updatedFeature.properties.skylark_id;
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        if (longstandingGeoJSONRef.current.features[i].skylark_id === skylarkId) {
          longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries.BoundaryChange = {
            ...longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries.BoundaryChange,
            [selectedYearEditing]: JSON.stringify(updatedFeature.geometry.coordinates)
          };
          longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries.Shape_Area = {
            ...longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries.Shape_Area,
            [selectedYearEditing]: turf.area(updatedFeature)
          };
        }
      }
      if (selectedYearEditing < years[years.length - 1]) {
        for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
          if (longstandingGeoJSONRef.current.features[i].skylark_id === skylarkId) {
            longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries.BoundaryChange = {
              ...longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries.BoundaryChange,
              [years[years.indexOf(selectedYearEditing) + 1]]: JSON.stringify(updatedFeature.geometry.coordinates)
            };
            longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries.Shape_Area = {
              ...longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries.Shape_Area,
              [years[years.indexOf(selectedYearEditing) + 1]]: turf.area(updatedFeature)
            };
          }
        }
      }
    });
  }
  setFinishedEditing(true);
  // updating = 1;
  // }
}

// Draw events

export const drawCreateHandler = (mapRef, longstandingGeoJSONRef, selectedYearEditing, selectedMetric, creating, setHasCreatedNewPolygonInModify, setIsLanduse, setFinishedEditing) => (e) => {
  if (creating === 0) {
    setFinishedEditing(false);
    console.log(selectedYearEditing);
    const originalFeature = e.features[0];
    console.log(originalFeature.geometry.coordinates);
    const areaInSquareMeters = turf.area(originalFeature) || 0;

    // Create a new feature with the necessary fields
    let newFeature = {
      geometry: originalFeature.geometry,
      skylark_id: uuidv4(),
      successor_skylark_id: null,
      external_ids: {
        searchlight_property_id: "101",  // Example static value, replace as necessary
        OBJECTID: "640",  // Example static value, replace as necessary
        OID_: "763",  // Example static value, replace as necessary
        id: "441"  // Example static value, replace as necessary
      },
      feature_data_standard: {
        created: selectedYearEditing,
        deleted: null
      },
      feature_data_standard_calculated: {
        Shape_Length: turf.length(originalFeature),
        Shape_Area: areaInSquareMeters
      },
      feature_data_simple: {
        HabitatClass: 1,
        HabitatType: "cereal crops",
        Confidence: "High",
        Source: "Digitised",
        LocalSignificance: 0,
        BDUnits_perHA: 1.205436033533801,
        BDUnits_Parcel: 55.076887829261324,
        Carbon_density_tC: 87.37483719032817,
        Carbon_flux_tCO2e: 9.278463138554695,
        ParcelCarbon: 285.54443208040783,
        ParcelCarbonFlux: 3.6261257014879122,
        b_metric: 100
      },
      feature_data_simple_timeseries: {
        BDUnits_perHA: {
          "2024-01-01T00:00:00+00:00": 1.205436033533801,
          "2027-01-01T00:00:00+00:00": 1.205436033533801,
          "2029-01-01T00:00:00+00:00": 1.205436033533801,
          "2031-01-01T00:00:00+00:00": 1.205436033533801
        },
        BDUnits_Parcel: {
          "2024-01-01T00:00:00+00:00": 55.076887829261324,
          "2027-01-01T00:00:00+00:00": 55.076887829261324,
          "2029-01-01T00:00:00+00:00": 55.076887829261324,
          "2031-01-01T00:00:00+00:00": 55.076887829261324
        },
        BoundaryChange: {
          "2024-01-01T00:00:00+00:00": JSON.stringify(originalFeature.geometry.coordinates),
          "2027-01-01T00:00:00+00:00": JSON.stringify(originalFeature.geometry.coordinates),
          "2029-01-01T00:00:00+00:00": JSON.stringify(originalFeature.geometry.coordinates),
          "2031-01-01T00:00:00+00:00": JSON.stringify(originalFeature.geometry.coordinates)
        },
        C_density_tC: {
          "2024-01-01T00:00:00+00:00": 87.37483719032817,
          "2027-01-01T00:00:00+00:00": 87.37483719032817,
          "2029-01-01T00:00:00+00:00": 87.37483719032817,
          "2031-01-01T00:00:00+00:00": 87.37483719032817
        },
        C_flux_tCO2e: {
          "2024-01-01T00:00:00+00:00": 9.278463138554695,
          "2027-01-01T00:00:00+00:00": 9.278463138554695,
          "2029-01-01T00:00:00+00:00": 9.278463138554695,
          "2031-01-01T00:00:00+00:00": 9.278463138554695
        },
        HabitatType: {
          "2024-01-01T00:00:00+00:00": "cereal crops",
          "2027-01-01T00:00:00+00:00": "cereal crops",
          "2029-01-01T00:00:00+00:00": "cereal crops",
          "2031-01-01T00:00:00+00:00": "cereal crops"
        },
        ParcelCarbon: {
          "2024-01-01T00:00:00+00:00": 285.54443208040783,
          "2027-01-01T00:00:00+00:00": 285.54443208040783,
          "2029-01-01T00:00:00+00:00": 285.54443208040783,
          "2031-01-01T00:00:00+00:00": 285.54443208040783
        },
        ParcelCarbonFlux: {
          "2024-01-01T00:00:00+00:00": 3.6261257014879122,
          "2027-01-01T00:00:00+00:00": 3.6261257014879122,
          "2029-01-01T00:00:00+00:00": 3.6261257014879122,
          "2031-01-01T00:00:00+00:00": 3.6261257014879122
        },
        Shape_Area: {
          "2024-01-01T00:00:00+00:00": areaInSquareMeters,
          "2027-01-01T00:00:00+00:00": areaInSquareMeters,
          "2029-01-01T00:00:00+00:00": areaInSquareMeters,
          "2031-01-01T00:00:00+00:00": areaInSquareMeters
        },
        ValuePSP: {
          "2024-01-01T00:00:00+00:00": 0,
          "2027-01-01T00:00:00+00:00": 0,
          "2029-01-01T00:00:00+00:00": 0,
          "2031-01-01T00:00:00+00:00": 0
        },
        Value_GBP: {
          "2024-01-01T00:00:00+00:00": 0,
          "2027-01-01T00:00:00+00:00": 0,
          "2029-01-01T00:00:00+00:00": 0,
          "2031-01-01T00:00:00+00:00": 0
        },
        carbon_density: {
          "2024-01-01T00:00:00+00:00": 87.37483719032817,
          "2027-01-01T00:00:00+00:00": 87.37483719032817,
          "2029-01-01T00:00:00+00:00": 87.37483719032817,
          "2031-01-01T00:00:00+00:00": 87.37483719032817
        }
      },
    };

    console.log(newFeature)

    // Add the new feature if no duplicate found
    setHasCreatedNewPolygonInModify(newFeature.skylark_id);
    longstandingGeoJSONRef.current.features.push(newFeature);

    console.log(longstandingGeoJSONRef.current);
    creating = 1;
  }
  setFinishedEditing(true);
}

export const drawDeleteHandler = (mapRef, longstandingGeoJSONRef, selectedYearEditing, deleting, setFinishedEditing) => (e) => {
setFinishedEditing(false);
  let selectedYearEditingCatch = "";
  selectedYearEditingCatch = selectedYearEditing;
  if (deleting === 0) {
    const updatedFeatures = e.features;
    if (updatedFeatures.length > 0) {
      updatedFeatures.forEach((updatedFeature) => {
        const skylarkId = updatedFeature.properties.skylark_id;
        for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
          if (longstandingGeoJSONRef.current.features[i].skylark_id === skylarkId) {
            longstandingGeoJSONRef.current.features[i].feature_data_standard.deleted = selectedYearEditing;
          }
        }
      });
    }
    deleting = 1;
  }
  setFinishedEditing(true);
}

export default initializeMapboxDraw;
