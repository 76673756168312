import React from 'react';
import { presetColors } from '../../../libs/colours'; // Ensure this is the correct path

const RenderedPTagsWithKey = ({
  selectedLandTypes,
  handleLandtypePTagClick,
  isUKHabColoursActive,
  uniqueTextureNamesArray,
  setcomparingLandtypeSelectedOption
}) => {

  const items = uniqueTextureNamesArray;

  for(let i = 0; i < items.length; i++) {
    if (items[i] === 'undefined') {
      items.splice(i, 1);
    }
  }

  const reset = 'Reset';

  const comparingLandtypeSelected = (landtype) => {
    // console.log('landtype', landtype);
    if (landtype === reset) {
      setcomparingLandtypeSelectedOption('none');
      return;
    }
    setcomparingLandtypeSelectedOption(landtype);
  };

  return (
    <>
      <div className="land_types_accum_compare">
        {items.map((item) => {
          const isSelected = selectedLandTypes.includes(item);
          // Assuming presetColors[item] gives the color for the item. If not, you might need a function to determine this.
          const backgroundColor = presetColors[item] || 'transparent'; // Fallback to 'transparent' if no color is found

          const capitalizeWords = (str) => {
            return str.replace(/\b\w/g, char => char.toUpperCase());
          };

          const truncatedItem = item.length > 30 ? `${item.substring(0, 30)}...` : item; // Truncate item at 30 characters
          const capitalizedTruncatedItem = capitalizeWords(truncatedItem); // Capitalize each word

          const imageSource = `${process.env.PUBLIC_URL}/ukhab/${item}.png`;
          return (
            <div
              key={item}
              onMouseMove={() => comparingLandtypeSelected(item)} // Wrap the function call inside an arrow function
              onMouseOut={() => comparingLandtypeSelected(reset)} // Wrap the function call inside an arrow function
              className={`land_type_list_general_p_compare ${isSelected ? 'selectedLandType' : ''}`}
            >
              <span className='color_circle' style={{ backgroundColor }}></span>
              <div className="land_type_item_text">
                <p className="land_type_item_key">{capitalizedTruncatedItem}</p> {/* Display truncatedItem instead of item */}
              </div>
            </div>
          );
        })}
      </div>
    </>
  )
};

export default RenderedPTagsWithKey;
