import { IoMdLocate } from "react-icons/io";
import { FaCompass } from "react-icons/fa";
import { isMobile } from 'react-device-detect';

const Compass = ({
    globalRefs,
    globalState,
    mapbearing
}) => {

    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;

    const mobileStatus = isMobile ? "mobile" : "not-mobile";

    return (
        <div className={`compass ${mobileStatus}`}>
            <button
                onClick={() => {
                    if (navigator.geolocation) {
                        polygonCustomLayerRef.current.cameraFlyToNorth();
                    } else {
                        alert('Geolocation is not supported by this browser.');
                    }
                }}
            >
                <p>Compass</p>
                <FaCompass style={{ transform: `rotate(${135 + mapbearing}deg)` }} color="#07EA77" className="compassIcon" size={25} />
            </button>
        </div>
    );
}

export default Compass;
