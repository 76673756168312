import React, { useState } from "react";

const ModifyPanel = ({
    endDrawOnClick,
    endDrawCancelOnClick,
    selectedYearEditing,
    setFinishedEditing,
    finishedEditing
}) => {

    return (
        <div className="modifyPanel">
            <div className="modifyPanelInner">
                {/* <h2>Editing Year: {selectedYearEditing}</h2> */}
                <div>
                    <div className={finishedEditing ? "end_draw active" : "end_draw"} onClick={endDrawOnClick}>
                        <button id="end_draw_button" className="end_draw_button">Confirm</button>
                    </div>
                    {/* <div className="end_draw_cancel" onClick={endDrawCancelOnClick}>
                        <button id="end_draw_button" className="end_draw_button_cancel">Cancel</button>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default ModifyPanel;
