import { createClient } from '@supabase/supabase-js';

// Initialize Supabase
const supabaseUrl = 'https://mfzxpxsyimhkxgeqrton.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1menhweHN5aW1oa3hnZXFydG9uIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjIzMjMzMjYsImV4cCI6MjAzNzg5OTMyNn0.Nxm2ksR4KuXGRXQpduzp33fKJIASBagx5_CwLLzxrP0';

let supabaseClient;

if (!supabaseClient) {
  supabaseClient = createClient(supabaseUrl, supabaseAnonKey);
}

export default supabaseClient;