import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { HiPencil } from "react-icons/hi";
import { BsBarChartFill } from "react-icons/bs";
import { IoMdEye } from "react-icons/io";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import RenderedPTags from "./leftSubMenuComponents/RenderedPTags.js";
import PieChart from "./leftSubMenuComponents/pieChart.js";
import ModifyPanel from "./leftSubMenuComponents/ModifyPanel.js";
import RenderedPTagsWithKey from "./leftSubMenuComponents/RenderedPTagsWithKey.js";
import Chart from 'chart.js/auto';
import { presetColors } from '../../libs/colours.js';
import { MdLandslide } from "react-icons/md";
import DropdownList from './leftSubMenuComponents/dropdownList.js';

const LeftSubMenu = ({
  isDrawing,
  beginDrawOnClick,
  endDrawOnClick,
  endDrawCancelOnClick,
  isSelectingLandtypes,
  toggleLeftSubDrawer,
  isLeftSubDrawerOpen,
  handleLandtypePTagClick,
  selectedLandTypes,
  isViewingPieChart,
  longstandingGeoJSONRef,
  originalGeoJSONforComparingRef,
  isViewingForecast,
  selectedYearEditing,
  setSelectedYearEditing,
  ModifySelectionCancelOnClick,
  isModifying,
  isComparing,
  isBenchmarking,
  handleYearChange,
  handleModifyClick,
  handleCompareClick,
  handleBenchmarkClick,
  selectedMetric,
  benchmarkingData,
  uniqueTextureNamesArray,
  isUKHabColoursActive,
  handleLandUseClick,
  isLanduse,
  polygonCustomLayerRef,
  isVisible,
  dropdownhighlightedIndex,
  setdropdownhighlightedIndex,
  setIsLanduse,
  Benchmarking,
  benchmarkingVisible,
  piechartRef,
  handleEnlargePieButtonClick,
  setcomparingLandtypeSelectedOption,
  resetLeftSubMenu,
  setisLeftSubDrawerOpen,
  storedfeaturecurrentlandtype,
  setStoredFeatureCurrentLandtype,
  temporaryGeoJSONIntendedForSaving,
  setTemporaryGeoJSONIntendedForSaving,
  setFinishedEditing,
  finishedEditing
}) => {

  let dateKey = selectedYearEditing;

  const chartRef = useRef(null); // useRef to hold the chart instance
  const selectedLandtype = useRef(null);
  let landtype;

  const allFeatureLandtypeMetric = (geojson, skylark_id) => {
    const newfeatures = longstandingGeoJSONRef.current.features;
    let metric = 0;
    newfeatures.forEach(feature => {
      if (feature.skylark_id === skylark_id) {
        landtype = feature.feature_data_simple_timeseries.HabitatType[dateKey]
        selectedLandtype.current = landtype;
      }
    });
    const features = geojson.features;
    let totalSum = 0;
    features.forEach(feature => {
      if (feature.feature_data_simple_timeseries.HabitatType[dateKey] === landtype) {

        let metric;

        if (feature &&
          feature.feature_data_simple_timeseries &&
          feature.feature_data_simple_timeseries.carbon_density &&
          feature.feature_data_simple_timeseries.carbon_density[dateKey] !== undefined) {
          metric = feature.feature_data_simple_timeseries.carbon_density[dateKey];
        } else {
          console.log("Some properties are undefined or not accessible.");
        }

        if (metric !== undefined) {
          totalSum += metric;
        }
      }
    });
    return totalSum;
  }

  const singleFeatureLandtypeMetric = (skylark_id) => {
    const features = longstandingGeoJSONRef.current.features;
    let metric = 0;
    features.forEach(feature => {
      if (feature.skylark_id === skylark_id) {
        metric = feature.feature_data_simple_timeseries.carbon_density[selectedYearEditing];
      }
    });
    return metric;
  }

  useEffect(() => {
    if (isBenchmarking && benchmarkingData) {
      console.log(benchmarkingData)
      const bar1 = singleFeatureLandtypeMetric(benchmarkingData);
      const bar2 = allFeatureLandtypeMetric(longstandingGeoJSONRef.current, benchmarkingData);
      const labels = ['Estate', 'National'];
      const dataValues = [bar1, bar2];
      const ctx = document.getElementById('barchart');
      if (chartRef.current) {
        chartRef.current.destroy(); // Destroy the existing chart before creating a new one
      }
      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            data: dataValues,
            backgroundColor: [
              presetColors[landtype], // Dynamic color for Bar 1
              presetColors[landtype]  // Dynamic color for Bar 2
            ],
            hoverOffset: 4
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                font: {
                  size: 18,
                },
              },
            },
            y: {
              display: false,
              grid: {
                display: true
              },
              ticks: {
                beginAtZero: true,
                font: {
                  size: 18,
                },
              },
            }
          }
        }
      });
    }
    // Cleanup function to destroy chart instance on component unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [benchmarkingData, isBenchmarking, selectedMetric]); // Removed longstandingGeoJSONRef.current from the dependencies array

  const conditionalClassName = benchmarkingData.length !== 0 ? "barchart" : "otherClass";

  return (
    <div>
      <Drawer
        open={isLeftSubDrawerOpen}
        direction='left'
        className='leftSubMenu'
        enableOverlay={false}
        duration={150}
      >

        <div onClick={toggleLeftSubDrawer} className="leftSubMenu_cancel">
          <button>
            Cancel
          </button>
        </div>

        <div>
          {isSelectingLandtypes ? (
            <div className="genericSubMenuHelpBox">
              <div className="genericSubMenuHelpBoxinner">
                <h1>Land Key</h1>
                <div className='land_type_list_general'>
                  <RenderedPTags
                    selectedLandTypes={selectedLandTypes}
                    handleLandtypePTagClick={handleLandtypePTagClick}
                    uniqueTextureNamesArray={uniqueTextureNamesArray}
                    isUKHabColoursActive={isUKHabColoursActive}
                  />
                </div>
              </div>
            </div>
          ) : (
            null
          )}
        </div>

        <div>
          {isViewingPieChart ? (
            <div className="genericSubMenuHelpBox">
              <div className="genericSubMenuHelpBoxinner">
                <h1>Pie Chart</h1>
                <div className="piechartdiv">
                  <PieChart
                    geojson={longstandingGeoJSONRef.current}
                    isViewingPieChart={isViewingPieChart}
                    selectedMetric={selectedMetric}
                    isUKHabColoursActive={isUKHabColoursActive}
                    handleLandtypePTagClick={handleLandtypePTagClick}
                    polygonCustomLayerRef={polygonCustomLayerRef}
                    piechartRef={piechartRef}
                    handleEnlargePieButtonClick={handleEnlargePieButtonClick}
                    selectedYearEditing={selectedYearEditing}
                  />
                </div>
              </div>
            </div>
          ) : (
            null
          )}
        </div>

        <div>
          <div>
            {isModifying ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Modify Map</h1>
                  {isModifying &&
                    <ModifyPanel
                      endDrawOnClick={endDrawOnClick}
                      endDrawCancelOnClick={endDrawCancelOnClick}
                      selectedYearEditing={selectedYearEditing}
                      setFinishedEditing={setFinishedEditing}
                      finishedEditing={finishedEditing}
                    />}
                  <p>
                    <h3>Modifying a Map</h3>
                    1 Click the polygon to Modify.<br/>
                    2 Double-click the yellow points.<br/>
                    3 Drag the points to your desired new shape.<br/>
                    4 Double-click the polygon to finalise.<br/>
                    <h3>Creating a New Land Type</h3>
                    1 Click the "Draw" button located above.<br/>
                    2 Click the map to create a new boundary.<br/>
                    3 Continue clicking to draw the desired shape.<br/>
                    4 Double-click to confirm the new boundary.<br/>
                    5 Click the "Confirm" button to finalise.<br/>
                    6 Select the new land type for the area.
                  </p>
                </div>
              </div>
            ) : (
              null
            )}
            {isComparing ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Land Type Key</h1>
                  <p>
                    <RenderedPTagsWithKey
                      selectedLandTypes={selectedLandTypes}
                      handleLandtypePTagClick={handleLandtypePTagClick}
                      isUKHabColoursActive={isUKHabColoursActive}
                      uniqueTextureNamesArray={uniqueTextureNamesArray}
                      setcomparingLandtypeSelectedOption={setcomparingLandtypeSelectedOption}
                    />
                  </p>
                </div>
              </div>
            ) : (
              null
            )}
            {isBenchmarking ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Benchmarking</h1>
                  <div class="scrollingBenchmarking">
                    <canvas id='barchart' className='barchart' height={100} />
                    <p>
                      UK soil contains about 10 billion tonnes
                      of carbon, roughly equal to 80 years of
                      annual greenhouse gas emissions.
                      <br /><br />
                      Source: Gov.uk
                    </p>

                    {/* {benchmarkingData.length !== 0 && ( */}
                    <Benchmarking
                      benchmarkingVisible={benchmarkingVisible}
                      benchmarkingData={benchmarkingData}
                      selectedMetric={selectedMetric}
                      isBenchmarking={isBenchmarking}
                      longstandingGeoJSONRef={longstandingGeoJSONRef}
                      selectedYearEditing={selectedYearEditing}
                    />
                    {/* )} */}
                  </div>
                </div>

              </div>
            ) : (
              null
            )}
            {isLanduse ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Change Land Use</h1>
                  <p>
                    1 Select the polygon on the map that you want to change the land use for<br />
                    2 From the list of available land types, choose the new land type you want to assign to the selected area.<br />
                    3 Click the "Confirm" button to apply the new land type to the selected polygon.
                  </p>
                  <DropdownList
                    isVisible={isVisible}
                    polygonCustomLayerRef={polygonCustomLayerRef}
                    selectedYearEditing={selectedYearEditing}
                    dropdownhighlightedIndex={dropdownhighlightedIndex}
                    setdropdownhighlightedIndex={setdropdownhighlightedIndex}
                    uniqueTextureNamesArray={uniqueTextureNamesArray}
                    isUKHabColoursActive={isUKHabColoursActive}
                    isLandUse={isLanduse}
                    setIsLanduse={setIsLanduse}
                    resetLeftSubMenu={resetLeftSubMenu}
                    setisLeftSubDrawerOpen={setisLeftSubDrawerOpen}
                    storedfeaturecurrentlandtype={storedfeaturecurrentlandtype}
                    setStoredFeatureCurrentLandtype={setStoredFeatureCurrentLandtype}
                    temporaryGeoJSONIntendedForSaving={temporaryGeoJSONIntendedForSaving}
                    setTemporaryGeoJSONIntendedForSaving={setTemporaryGeoJSONIntendedForSaving}
                  />
                </div>
              </div>
            ) : (
              null
            )}
          </div>
        </div>

        <button className='togglerDrawOutside' onClick={toggleLeftSubDrawer}>
          <IoIosArrowBack />
        </button>
      </Drawer>
    </div>
  );
};

export default LeftSubMenu;