export const results_tranlsated = {
  "cropland": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "arable and horticulture": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "arable field margins": {
    "BD_Parcel": 899.8,
    "BD_Parcel_original": 8.998,
    "ParcelCarbon": 10901.8,
    "ParcelCarbon_original": 109.018,
    "ParcelCarbonFlux": -71.9,
    "ParcelCarbonFlux_original": -0.719
  },
  "temporary grasslands and clover leys": {
    "BD_Parcel": 899.8,
    "BD_Parcel_original": 8.998,
    "ParcelCarbon": 10901.8,
    "ParcelCarbon_original": 109.018,
    "ParcelCarbonFlux": -71.9,
    "ParcelCarbonFlux_original": -0.719
  },
  "cereal crops": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "non-cereal crops": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "intensive orchards": {
    "BD_Parcel": 899.8,
    "BD_Parcel_original": 8.998,
    "ParcelCarbon": 10901.8,
    "ParcelCarbon_original": 109.018,
    "ParcelCarbonFlux": -71.9,
    "ParcelCarbonFlux_original": -0.719
  },
  "horticulture": {
    "BD_Parcel": 899.8,
    "BD_Parcel_original": 8.998,
    "ParcelCarbon": 10901.8,
    "ParcelCarbon_original": 109.018,
    "ParcelCarbonFlux": -71.9,
    "ParcelCarbonFlux_original": -0.719
  },
  "urban": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 4112.4,
    "ParcelCarbon_original": 41.124,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "built-up areas and gardens": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 4112.4,
    "ParcelCarbon_original": 41.124,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "open mosaic undeveloped land": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 0,
    "ParcelCarbon_original": 0,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "developed, bare sealed surface": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 4112.4,
    "ParcelCarbon_original": 41.124,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "buildings": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 4112.4,
    "ParcelCarbon_original": 41.124,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "other developed land": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 4112.4,
    "ParcelCarbon_original": 41.124,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "artificial unvegetated sealed surface": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 4112.4,
    "ParcelCarbon_original": 41.124,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "suburban natural surface": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 4112.4,
    "ParcelCarbon_original": 41.124,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "build linear features": {
    "BD_Parcel": 0,
    "BD_Parcel_original": 0,
    "ParcelCarbon": 4112.4,
    "ParcelCarbon_original": 41.124,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "grassland": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "acid grassland": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "lowland dry acid grassland": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "upland acid grassland": {
    "BD_Parcel": 3503.8,
    "BD_Parcel_original": 35.038,
    "ParcelCarbon": 26534.7,
    "ParcelCarbon_original": 265.347,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "montane acid grasslands": {
    "BD_Parcel": 3503.8,
    "BD_Parcel_original": 35.038,
    "ParcelCarbon": 26534.7,
    "ParcelCarbon_original": 265.347,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "other upland acid grassland": {
    "BD_Parcel": 3503.8,
    "BD_Parcel_original": 35.038,
    "ParcelCarbon": 26534.7,
    "ParcelCarbon_original": 265.347,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "bracken": {
    "BD_Parcel": 322.8,
    "BD_Parcel_original": 3.228,
    "ParcelCarbon": 15818.2,
    "ParcelCarbon_original": 158.182,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "calcareous grassland": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "lowland calcareous grassland": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "upland calcareous grassland": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "neutral grassland": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "lowland meadows": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "lowland unimproved grassland": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "upland hay meadows": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "upland unimproved grassland": {
    "BD_Parcel": 1432.6,
    "BD_Parcel_original": 14.326,
    "ParcelCarbon": 10687.7,
    "ParcelCarbon_original": 106.877,
    "ParcelCarbonFlux": 0,
    "ParcelCarbonFlux_original": 0
  },
  "improved grassland": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "upland improved grassland": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "neutral damp grassland": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "improved and neutral damp grassland": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "improved grassland_damp grassland": {
    "BD_Parcel": 547.1,
    "BD_Parcel_original": 5.471,
    "ParcelCarbon": 13781.4,
    "ParcelCarbon_original": 137.814,
    "ParcelCarbonFlux": 79.3,
    "ParcelCarbonFlux_original": 0.793
  },
  "rough grassland": {
    "BD_Parcel": 899.8,
    "BD_Parcel_original": 8.998,
    "ParcelCarbon": 10901.8,
    "ParcelCarbon_original": 109.018,
    "ParcelCarbonFlux": -71.9,
    "ParcelCarbonFlux_original": -0.719
  },
  "grass moorland": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "bracken and montane": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "montane grassland and montane": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "montane and heathland": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "marsh grassland": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "heathland": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "dwarf shrub heath": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "heather dominated dwarf shrub": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "heather dominated dwarf shrub heath": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "heather moorland": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "upland dwarf shrub": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "upland dwarf shrub heath": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "heath and heather moorland": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "lowland heathland": {
    "BD_Parcel": 899.8,
    "BD_Parcel_original": 8.998,
    "ParcelCarbon": 10901.8,
    "ParcelCarbon_original": 109.018,
    "ParcelCarbonFlux": -71.9,
    "ParcelCarbonFlux_original": -0.719
  },
  "grass and dwarf shrub heath": {
    "BD_Parcel": 1521.9,
    "BD_Parcel_original": 15.219,
    "ParcelCarbon": 22519.5,
    "ParcelCarbon_original": 225.195,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "scrub": {
    "BD_Parcel": 2048.3,
    "BD_Parcel_original": 20.483,
    "ParcelCarbon": 14909.2,
    "ParcelCarbon_original": 149.092,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "scrub and woody pasture": {
    "BD_Parcel": 2048.3,
    "BD_Parcel_original": 20.483,
    "ParcelCarbon": 14909.2,
    "ParcelCarbon_original": 149.092,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "lowland scrub and woodland": {
    "BD_Parcel": 2048.3,
    "BD_Parcel_original": 20.483,
    "ParcelCarbon": 14909.2,
    "ParcelCarbon_original": 149.092,
    "ParcelCarbonFlux": 14.3,
    "ParcelCarbonFlux_original": 0.143
  },
  "bracken": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "montane": {
    "BD_Parcel": 1952.9,
    "BD_Parcel_original": 19.529,
    "ParcelCarbon": 13692.7,
    "ParcelCarbon_original": 136.927,
    "ParcelCarbonFlux": 8.5,
    "ParcelCarbonFlux_original": 0.085
  },
  "fen marsh and swamp": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "fen": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "reedbed": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "floodplain and grazing marsh": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "purple moor grass and rush pasture": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "lowland fen": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "upland fen": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "floodplain": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "grazing marsh": {
    "BD_Parcel": 2484.6,
    "BD_Parcel_original": 24.846,
    "ParcelCarbon": 15298.3,
    "ParcelCarbon_original": 152.983,
    "ParcelCarbonFlux": 2.4,
    "ParcelCarbonFlux_original": 0.024
  },
  "bog": {
    "BD_Parcel": 3525.3,
    "BD_Parcel_original": 35.253,
    "ParcelCarbon": 22771.6,
    "ParcelCarbon_original": 227.716,
    "ParcelCarbonFlux": 1.4,
    "ParcelCarbonFlux_original": 0.014
  },
  "lowland raised bog": {
    "BD_Parcel": 3525.3,
    "BD_Parcel_original": 35.253,
    "ParcelCarbon": 22771.6,
    "ParcelCarbon_original": 227.716,
    "ParcelCarbonFlux": 1.4,
    "ParcelCarbonFlux_original": 0.014
  },
  "upland raised bog": {
    "BD_Parcel": 3525.3,
    "BD_Parcel_original": 35.253,
    "ParcelCarbon": 22771.6,
    "ParcelCarbon_original": 227.716,
    "ParcelCarbonFlux": 1.4,
    "ParcelCarbonFlux_original": 0.014
  },
  "blanket bog": {
    "BD_Parcel": 3525.3,
    "BD_Parcel_original": 35.253,
    "ParcelCarbon": 22771.6,
    "ParcelCarbon_original": 227.716,
    "ParcelCarbonFlux": 1.4,
    "ParcelCarbonFlux_original": 0.014
  },
  "upland blanket bog": {
    "BD_Parcel": 3525.3,
    "BD_Parcel_original": 35.253,
    "ParcelCarbon": 22771.6,
    "ParcelCarbon_original": 227.716,
    "ParcelCarbonFlux": 1.4,
    "ParcelCarbonFlux_original": 0.014
  },
  "peat bog": {
    "BD_Parcel": 3525.3,
    "BD_Parcel_original": 35.253,
    "ParcelCarbon": 22771.6,
    "ParcelCarbon_original": 227.716,
    "ParcelCarbonFlux": 1.4,
    "ParcelCarbonFlux_original": 0.014
  },
  "open water": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "canal": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "river": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "lake": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "standing water": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "standing water in standing open water": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "marine": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "subtidal": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "seagrass": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "subtidal sediments and sand": {
    "BD_Parcel": 2,
    "BD_Parcel_original": 0.02,
    "ParcelCarbon": 37.7,
    "ParcelCarbon_original": 0.377,
    "ParcelCarbonFlux": 1.3,
    "ParcelCarbonFlux_original": 0.013
  },
  "intertidal": {
    "BD_Parcel": 5,
    "BD_Parcel_original": 0.05,
    "ParcelCarbon": 153.1,
    "ParcelCarbon_original": 1.531,
    "ParcelCarbonFlux": 29.2,
    "ParcelCarbonFlux_original": 0.292
  },
  "saltmarsh": {
    "BD_Parcel": 5,
    "BD_Parcel_original": 0.05,
    "ParcelCarbon": 153.1,
    "ParcelCarbon_original": 1.531,
    "ParcelCarbonFlux": 29.2,
    "ParcelCarbonFlux_original": 0.292
  },
  "mudflats": {
    "BD_Parcel": 5,
    "BD_Parcel_original": 0.05,
    "ParcelCarbon": 153.1,
    "ParcelCarbon_original": 1.531,
    "ParcelCarbonFlux": 29.2,
    "ParcelCarbonFlux_original": 0.292
  },
  "saline lagoon": {
    "BD_Parcel": 5,
    "BD_Parcel_original": 0.05,
    "ParcelCarbon": 153.1,
    "ParcelCarbon_original": 1.531,
    "ParcelCarbonFlux": 29.2,
    "ParcelCarbonFlux_original": 0.292
  },
  "sparsely vegetated": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "dune": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "machair": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "shingle": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "sand": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "cliff": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "rocky shore": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "scree": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "coastal vegetated shingle": {
    "BD_Parcel": 0.4,
    "BD_Parcel_original": 0.004,
    "ParcelCarbon": 13.1,
    "ParcelCarbon_original": 0.131,
    "ParcelCarbonFlux": 12.6,
    "ParcelCarbonFlux_original": 0.126
  },
  "suburban": {
    "BD_Parcel": 72.6,
    "BD_Parcel_original": 0.726,
    "ParcelCarbon": 30,
    "ParcelCarbon_original": 0.3,
    "ParcelCarbonFlux": -42.6,
    "ParcelCarbonFlux_original": -0.426
  },
  "urban": {
    "BD_Parcel": 72.6,
    "BD_Parcel_original": 0.726,
    "ParcelCarbon": 30,
    "ParcelCarbon_original": 0.3,
    "ParcelCarbonFlux": -42.6,
    "ParcelCarbonFlux_original": -0.426
  },
  "woodland": {
    "BD_Parcel": 2245.4,
    "BD_Parcel_original": 22.454,
    "ParcelCarbon": 35809.1,
    "ParcelCarbon_original": 358.091,
    "ParcelCarbonFlux": 13.3,
    "ParcelCarbonFlux_original": 0.133
  },
  "lowland woodland": {
    "BD_Parcel": 2245.4,
    "BD_Parcel_original": 22.454,
    "ParcelCarbon": 35809.1,
    "ParcelCarbon_original": 358.091,
    "ParcelCarbonFlux": 13.3,
    "ParcelCarbonFlux_original": 0.133
  },
  "upland woodland": {
    "BD_Parcel": 2245.4,
    "BD_Parcel_original": 22.454,
    "ParcelCarbon": 35809.1,
    "ParcelCarbon_original": 358.091,
    "ParcelCarbonFlux": 13.3,
    "ParcelCarbonFlux_original": 0.133
  },
  "wet woodland": {
    "BD_Parcel": 2245.4,
    "BD_Parcel_original": 22.454,
    "ParcelCarbon": 35809.1,
    "ParcelCarbon_original": 358.091,
    "ParcelCarbonFlux": 13.3,
    "ParcelCarbonFlux_original": 0.133
  },
  "upland wet woodland": {
    "BD_Parcel": 2245.4,
    "BD_Parcel_original": 22.454,
    "ParcelCarbon": 35809.1,
    "ParcelCarbon_original": 358.091,
    "ParcelCarbonFlux": 13.3,
    "ParcelCarbonFlux_original": 0.133
  },
  "plantation": {
    "BD_Parcel": 3211.2,
    "BD_Parcel_original": 32.112,
    "ParcelCarbon": 42547.7,
    "ParcelCarbon_original": 425.477,
    "ParcelCarbonFlux": 10.6,
    "ParcelCarbonFlux_original": 0.106
  },
  "plantation woodland": {
    "BD_Parcel": 3211.2,
    "BD_Parcel_original": 32.112,
    "ParcelCarbon": 42547.7,
    "ParcelCarbon_original": 425.477,
    "ParcelCarbonFlux": 10.6,
    "ParcelCarbonFlux_original": 0.106
  },
  "upland plantation woodland": {
    "BD_Parcel": 3211.2,
    "BD_Parcel_original": 32.112,
    "ParcelCarbon": 42547.7,
    "ParcelCarbon_original": 425.477,
    "ParcelCarbonFlux": 10.6,
    "ParcelCarbonFlux_original": 0.106
  },
  "wood pasture and parkland": {
    "BD_Parcel": 3211.2,
    "BD_Parcel_original": 32.112,
    "ParcelCarbon": 42547.7,
    "ParcelCarbon_original": 425.477,
    "ParcelCarbonFlux": 10.6,
    "ParcelCarbonFlux_original": 0.106
  },
  "upland wood pasture and parkland": {
    "BD_Parcel": 3211.2,
    "BD_Parcel_original": 32.112,
    "ParcelCarbon": 42547.7,
    "ParcelCarbon_original": 425.477,
    "ParcelCarbonFlux": 10.6,
    "ParcelCarbonFlux_original": 0.106
  }
}
