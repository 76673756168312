import React from 'react';
import { MdLandslide } from "react-icons/md";

const LandUseButton = ({
    isLanduse,
    handleLandUseClick
}) => {
    return (
        <div className={`land_types ${isLanduse ? 'selected' : ''}`} onClick={handleLandUseClick}>
            <button disabled>
                <MdLandslide className="leftMenuIcons" /> Land Use
            </button>
        </div>
    );
};

export default LandUseButton;