import React from 'react';

const SavedStatus = ({ isHasSaved, savedFilename, isHasSavedCurrentData, loadedFileName }) => {
  return (
    <>
      {isHasSaved && <div className='saved_filename_text'>Saved as {savedFilename}</div>}
      {isHasSavedCurrentData && <div className='saved_filename_text'>Saved {loadedFileName}</div>}
      {/* <div className='loadedFileName'>Editing: {loadedFileName}</div> */}
    </>
  );
};

export default SavedStatus;