import React, { useState } from "react";
import 'react-modern-drawer/dist/index.css';
import logo from '../../images/logo.png';
import { IoIosArrowDown } from "react-icons/io";

const TacPanel = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    // Helper function to get the appropriate class name for tab content
    const getTabContentClassName = (tabIndex) => {
        return activeTab === tabIndex ? "tabContentActive" : "tabContent";
    };

    // Helper function to get the appropriate class name for the arrow
    const getArrowClassName = (tabIndex) => {
        return activeTab === tabIndex ? "arrow_down arrow_rotate" : "arrow_down";
    };

    return (
        <div className='about'>
            <div className="about_inner">
                <div className="about_inner_top_section">
                    <img src={logo} className='logo' alt='Skylark Logo' />
                    <h2>Skylark Terms and Conditions</h2>
                    <p>Welcome to Skylark! By accessing or using our website, you agree to comply with and be bound by the following terms and conditions, which together with our Privacy Policy, govern Skylark's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</p>
                    <button className="about_button">Download PDF Privacy Statement</button>

                    <div className="tabsystem">
                        <button className={"abouttab_button"} onClick={() => handleTabClick(1)}>What is personal data at Skylark?
                            <div className={getArrowClassName(1)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(1)}>
                            <p>
                                Personal data at Skylark refers to any information that can be used to identify you, either directly or indirectly. This includes, but is not limited to, your name, address, email address, phone number, account details, payment information, IP address, and any other data that can be linked to you. We collect this data through your interactions with our website, software applications, or services, such as when you register for an account, make a purchase, or subscribe to our newsletters. This data helps us provide you with personalized experiences and improve our services.
                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(2)}>Your privacy rights at Skylark
                            <div className={getArrowClassName(2)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(2)}>
                            <p>
                                At Skylark, we are committed to protecting your privacy and ensuring you have control over your personal data. You have the right to:
                            </p>
                            <ul>
                                <li>Request a copy of the personal data we hold about you, known as a Data Subject Access Request.</li>
                                <li>Request corrections to any inaccurate or incomplete personal data.</li>
                                <li>Request the deletion of your personal data under certain conditions, also known as the "right to be forgotten".</li>
                                <li>Object to or restrict the processing of your personal data in certain circumstances.</li>
                                <li>Withdraw your consent at any time, where we are processing your data based on your consent.</li>
                                <li>Receive your personal data in a structured, commonly used, and machine-readable format, and have the right to transmit those data to another controller (data portability).</li>
                            </ul>
                            <p>
                                You can exercise these rights by contacting us through our privacy request form or via email. Additionally, you can opt out of marketing emails by clicking the “unsubscribe” link at the bottom of any email communication.
                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(3)}>Personal data Skylark collects from you
                            <div className={getArrowClassName(3)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(3)}>
                            <p>
                                Skylark collects data that you provide directly to us through various means, including:
                            </p>
                            <ul>
                                <li>Registration forms when you create an account.</li>
                                <li>Order forms when you purchase products or services.</li>
                                <li>Contact forms and customer support interactions.</li>
                                <li>Subscription forms for newsletters or other communications.</li>
                                <li>Surveys, feedback forms, or other research activities.</li>
                            </ul>
                            <p>
                                This data typically includes your name, address, email address, phone number, payment information, and any other information you choose to provide. We use this data to manage your account, process your orders, communicate with you, and improve our services.
                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(4)}>Personal data Skylark receives from other sources
                            <div className={getArrowClassName(4)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(4)}>
                            <p>
                                In addition to the data you provide directly, Skylark may receive information about you from other sources, including:
                            </p>
                            <ul>
                                <li>Third-party services you use, such as social media platforms. For example, if you choose to link your social media account with Skylark, we may receive data from those platforms based on your privacy settings and the platform's policies.</li>
                                <li>Partners and affiliates who provide us with information to enhance our services, such as demographic information and online behavioral data.</li>
                                <li>Publicly available sources, such as public profiles on social media or other online forums.</li>
                                <li>Technical data from analytics providers and advertising networks, which can include your IP address, browser type, operating system, and information about how you interact with our services.</li>
                            </ul>
                            <p>
                                We use this data to better understand your preferences, personalize your experience, and enhance the security and performance of our services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TacPanel;
