import React from 'react';
import { BsBarChartFill } from "react-icons/bs";
import { PiScalesFill } from "react-icons/pi";

const CompareButton = ({
    isComparing,
    handleCompareClick
}) => {
    return (
        <div className={`land_types ${isComparing ? 'selected' : ''}`} onClick={handleCompareClick}>
            <button disabled>
                <PiScalesFill className="leftMenuIcons" /> Compare
            </button>
        </div>
    );
};

export default CompareButton;