export const largePieChartBio =
{
    "habitats": [
        {
            "name": "upland mixed ashwoods",
            "description": "Diverse woodland ecosystem found in upland areas with a mix of ash trees and other species, supporting varied wildlife."
        },
        {
            "name": "cereal crops",
            "description": "Fields cultivated for cereal production like wheat, barley, or corn, crucial for global food supply and agricultural economy."
        },
        {
            "name": "supralittoral sediment",
            "description": "Coastal sedimentary habitats above the waterline, supporting unique flora and fauna adapted to tidal influences."
        },
        {
            "name": "other woodland mixed",
            "description": "Mixed woodland areas not primarily ashwoods, offering habitat diversity for a range of plant and animal species."
        },
        {
            "name": "artificial unvegetated sealed surface",
            "description": "Mixed woodland areas not primarily ashwoods, offering habitat diversity for a range of plant and animal species."
        },
        {
            "name": "modified grassland",
            "description": "Grasslands altered by human activity for agricultural or landscaping purposes, influencing biodiversity and ecosystem services."
        },
        {
            "name": "lowland calcareous grassland",
            "description": "Species-rich grasslands on calcareous soils, supporting diverse flora and fauna adapted to these unique environmental conditions."
        },
        {
            "name": "lowland heath",
            "description": "Heathland ecosystems in lowland areas, characterized by acidic soils and heath plants, providing habitat for specialized wildlife."
        },
        {
            "name": "lowland dry acid grassland",
            "description": "Dry grasslands on acidic soils in lowland areas, supporting a variety of grass species and wildlife adapted to these conditions."
        },
        {
            "name": "lowland mixed deciduous woodland",
            "description": "Mixed woodland ecosystems in lowland areas, dominated by deciduous trees and supporting a variety of plant and animal species."
        },
        {
            "name": "arable field margins",
            "description": "Strips of uncultivated land bordering arable fields, providing habitat for wildlife and supporting ecosystem services like pollination."
        },
        {
            "name": "intensive orchards",
            "description": "Intensively managed orchards for fruit production, providing habitat for pollinators and supporting traditional agricultural practices."
        },
        {
            "name": "urban",
            "description": "Areas with high population density and extensive human infrastructure, influencing local biodiversity and environmental quality."
        },
        {
            "name": "hedgerow, priority habitat",
            "description": "Hedges with high ecological value due to their age, species diversity, and role as wildlife corridors and nesting sites."
        },
        {
            "name": "upland oakwood",
            "description": "Woodland ecosystems in upland areas dominated by oak trees, supporting diverse wildlife and providing essential ecosystem services."
        },
        {
            "name": "other neutral grassland",
            "description": "Neutral grasslands not classified under specific categories, providing habitat for diverse wildlife and supporting traditional land uses."
        },
        {
            "name": "lowland meadows",
            "description": "Fertile lowland areas with diverse grasses and wildflowers, important for pollinators and traditional haymaking practices."
        },
        {
            "name": "artificial unvegetated",
            "description": "Man-made areas devoid of vegetation, such as paved surfaces or bare soil, often found in urban or industrial settings."
        },
        {
            "name": "non-cereal crops",
            "description": "Cultivated plants excluding cereals, encompassing a variety of fruits, vegetables, and cash crops like cotton or sugarcane."
        },
        {
            "name": "other coniferous woodland",
            "description": "Woodlands dominated by coniferous trees other than ash, providing habitat for wildlife adapted to these specific ecosystems."
        },
        {
            "name": "sparsely vegetated land",
            "description": "Areas with limited vegetation cover, often characterized by harsh environmental conditions and specialized plant adaptations."
        },
        {
            "name": "Lolium-Cynodon neutral",
            "description": "Neutral grasslands with a mix of grass species like Lolium and Cynodon, supporting grazing animals and maintaining soil stability."
        },
        {
            "name": "dense shrub",
            "description": "Areas densely populated with shrubs and bushes, offering shelter and food for small mammals, birds, and invertebrates."
        },
        {
            "name": "developed, bare sealed",
            "description": "Highly urbanized areas extensively covered by buildings and impermeable surfaces, reducing green space and ecological connectivity."
        },
        {
            "name": "built-up areas and gardens",
            "description": "Urban and suburban landscapes dominated by human infrastructure, including residential areas, parks, and cultivated gardens."
        },
        {
            "name": "suburban natural surfaces",
            "description": "Natural areas within suburban settings, providing green spaces for recreation, wildlife habitat, and ecosystem services."
        },
        {
            "name": "cropland",
            "description": "Land dedicated to agricultural cultivation for food, fiber, or fuel production, essential for sustaining growing populations and economies."
        },
        {
            "name": "neutral grassland",
            "description": "Grassland ecosystems with a balanced mix of grass species, providing habitat for diverse wildlife and supporting livestock grazing."
        },
        {
            "name": "temporary grasslands and meadows",
            "description": "Seasonally inundated or managed grasslands and meadows, important for flood regulation, biodiversity, and traditional farming practices."
        },
        {
            "name": "Lolium-Cynodon neutral grassland",
            "description": "Seasonally inundated or managed grasslands and meadows, important for flood regulation, biodiversity, and traditional farming practices."
        },
        {
            "name": "developed, bare sealed surface",
            "description": "Seasonally inundated or managed grasslands and meadows, important for flood regulation, biodiversity, and traditional farming practices."
        },
        {
            "name": "suburban natural surface",
            "description": "Seasonally inundated or managed grasslands and meadows, important for flood regulation, biodiversity, and traditional farming practices."
        },
        {
            "name": "temporary grasslands and clover leys",
            "description": "Seasonally inundated or managed grasslands and meadows, important for flood regulation, biodiversity, and traditional farming practices."
        },
        {
            "name": "build linear features",
            "description": "Seasonally inundated or managed grasslands and meadows, important for flood regulation, biodiversity, and traditional farming practices."
        }
    ]
}
