import {
    fmesh,
    fullmesh,
    loading_timer_count
} from './globalVariables.js';
import SkylarkPolygonLayer from './skylarkPolygonLayer.js';

const ResetMapMetricPublic = async (globalRefs, globalState) => {

    let centroidRef = globalRefs.centroidRef;

    let setLoadedPercentage = globalState.setLoadedPercentage;

    let mapRef = globalRefs.mapRef;
    let drawRef = globalRefs.drawRef;
    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
    let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;

    let isDrawing = globalState.isDrawing;

    if (isDrawing) {
        mapRef.current.removeControl(drawRef.current);
        mapRef.current.off('draw.create');
        mapRef.current.off('draw.delete');
    }

    let isLoggedIntoAPI = globalState.loggedInToAPI;

    setLoadedPercentage(100);

    let isVisible = globalState.isVisible;
    let setIsVisible = globalState.setIsVisible;

    setTimeout(() => {
        fmesh.children.forEach((child) => {
            child.geometry.dispose();
            child.material.dispose();
        });
        fullmesh.children.forEach((child) => {
            fullmesh.remove(child);
        });
        fmesh.children.forEach((child) => {
            fmesh.remove(child);
        });
        fmesh.children.length = 0;
        fullmesh.children.length = 0;
        mapRef.current.removeLayer('Polygons');
        polygonCustomLayerRef.current = new SkylarkPolygonLayer(
            'Polygons',
            centroidRef.current.geometry.coordinates[1] + 0.01,
            centroidRef.current.geometry.coordinates[0] - 0.01,
            longstandingGeoJSONRef.current,
            globalRefs,
            globalState,
            false
        );
        setIsVisible(false);
        mapRef.current.addLayer(polygonCustomLayerRef.current);
    }, loading_timer_count);
}

export default ResetMapMetricPublic;