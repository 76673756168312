import React, { useState } from "react";
import 'react-modern-drawer/dist/index.css';
import logo from '../../images/logo.png';
import { IoIosArrowDown } from "react-icons/io";

const PrivacyPanel = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    // Helper function to get the appropriate class name for tab content
    const getTabContentClassName = (tabIndex) => {
        return activeTab === tabIndex ? "tabContentActive" : "tabContent";
    };

    // Helper function to get the appropriate class name for the arrow
    const getArrowClassName = (tabIndex) => {
        return activeTab === tabIndex ? "arrow_down arrow_rotate" : "arrow_down";
    };

    return (
        <div className='about'>
            <div className="about_inner">
                <div className="about_inner_top_section">
                    <img src={logo} className='logo' alt='Skylark Logo' />
                    <h2>Skylark Privacy</h2>
                    <p>
                        This policy sets out the various aspects concerning user privacy for Skylark and outlines the responsibilities and requirements for users, the website, and the website owners. Additionally, this policy details how Skylark processes, stores, and safeguards user data and information.
                    </p>
                    <button className="about_button">Download PDF Privacy Statement</button>

                    <div className="tabsystem">
                        <button className={"abouttab_button"} onClick={() => handleTabClick(1)}>
                            How We Use Cookies
                            <div className={getArrowClassName(1)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(1)}>
                            <p>
                                At Skylark, cookies may be employed to recall personal preferences and settings that you have selected on our website. It’s important to note that we do not employ cookies to collect information that identifies you on a personal level. Most of the cookies we set are automatically deleted from your computer after you leave our website or shortly thereafter. We use anonymous session cookies (temporary cookies that are deleted when you close your browser) to aid in navigating the website and making the most of its features.

                                If you log into our website, application, or courses as a registered user, your session cookie will also contain your user ID. This enables us to verify the services you are authorized to access. Our website employs tracking software from Google Analytics to gain insights into visitor behavior and usage patterns. Google Analytics utilizes cookies to track visitor engagement and usage, but it does not store, save, or collect personal information. Users who wish to decline the use and storage of cookies from Skylark’s website can adjust their web browser’s security settings to block all cookies from our website and its external service providers.
                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(2)}>
                            Personal Information
                            <div className={getArrowClassName(2)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(2)}>
                            <p>
                                During your interactions with our website, software applications, or services, you might be required to provide personal information such as your name, address, email, and account details. This information is used to manage our website, applications, client databases, and marketing materials. We are committed to securely storing all provided personal information in compliance with the General Data Protection Regulation (EU) 2016/679, as adopted into UK law by the Data Protection Act 2018. Additionally, by supplying telephone, fax, and email details, you provide consent for Skylark to contact you using those methods. You possess the right to request a copy of the personal information we have on file for you. If you wish to receive a copy of this information or want to be removed from our database, kindly contact us at hello@skylarkcompany.com.
                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(3)}>
                            Personal data Skylark collects from you
                            <div className={getArrowClassName(3)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(3)}>
                            <p>
                                Skylark gathers information through two primary methods:

                                <br/>

                                Directly Provided Data: When you sign up for our website, purchase products, or communicate with us, you may voluntarily provide information by filling in text fields or completing registration forms. This information requires your active input for us to receive it.
                                User Authorized Data: Depending on your settings or the privacy policies of other online services, you may grant us permission to access information from your accounts with those services. This might occur through social media channels or when you opt to share location data while accessing our website via your smartphone.
                                Data Retention

                                Skylark is committed to retaining your personal information for only as long as necessary. We will keep the information you provide either while your account is active or for the duration needed to provide our services to you. If required by law, regulatory obligations, or for dispute resolution, fraud prevention, and enforcement of our Terms and Conditions, we may retain some of your information for a limited period, even after you close your account or the information is no longer necessary to provide our services.


                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(4)}>
                            Managing Data Usage
                            <div className={getArrowClassName(4)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(4)}>
                            <p>
                                We understand the trust you place in us with your personal information. To ensure you can manage the privacy and security of your data yourself, we commit to the following:

                                <br/>

                                Verification and Correction: You can verify the details you have submitted to Skylark by reaching out to our customer services team at hello@skylarkcompany.com. For security reasons, we might request proof of identity before disclosing certain information, including your email address and possibly your physical address.
                                Modification and Deletion: Using the same contact method, you can also request changes, corrections, or deletion of the personal information Skylark controls regarding your profile. Please be aware that if you’ve shared information via social media channels, that data may remain visible even after your account is deleted. You also have the option to close your account through our account settings. If you choose this, your account will be deactivated. Nonetheless, we may maintain archived copies of your information in compliance with the law or for legitimate business purposes, including addressing fraud and spam.
                                Unsubscribing from Marketing Emails: You can opt out of receiving marketing emails from Skylark by clicking the “unsubscribe” link at the bottom of any email. Once you do this, you will no longer receive marketing emails from us.
                                Data Access Requests: You have the right to request a readable copy of the personal data we have on record for you. To do so, please contact us at hello@skylarkcompany.com.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPanel;
