import React, { useEffect, useRef, useState } from "react";
import Chart from 'chart.js/auto';
import { presetColors } from '../../../libs/colours.js';
import { IoClose } from "react-icons/io5";
import { largePieChartBio } from '../../../libs/largePieChartBio.js';

const LargePieChart = ({
    geojson,
    isViewingPieChart,
    selectedMetric,
    isUKHabColoursActive,
    polygonCustomLayerRef,
    handleEnlargePieButtonClick,
    handleClosePieButtonClick,
    selectedYearEditing
}) => {

    let dateKey = '2024-01-01T00:00:00+00:00';

    let metric;

    const [selectedLandType, setSelectedLandType] = useState(null);
    const [selectedLandTypeCapitalised, setSelectedLandTypeCapitalised] = useState(null);

    const largepiechartRef = useRef(null);

    console.log(largePieChartBio)

    const calculatePercentages = (geojson) => {

        const sumTotal = {};
        const features = geojson.features;
        let grandTotal = 0;

        features.forEach(feature => {
            const propertyValue = feature.feature_data_simple.HabitatType;

            if (feature &&
                feature.feature_data_simple_timeseries &&
                feature.feature_data_simple_timeseries[selectedMetric] &&
                feature.feature_data_simple_timeseries[selectedMetric][selectedYearEditing] !== undefined) {
                metric = feature.feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
            } else {
                console.log("Some properties are undefined or not accessible.");
            }

            if (propertyValue !== undefined && metric !== undefined) {
                const roundedMetric = Math.round(metric);
                sumTotal[propertyValue] = (sumTotal[propertyValue] || 0) + roundedMetric;
                grandTotal += roundedMetric;
            }
        });

        const percentages = {};
        for (const property in sumTotal) {
            percentages[property] = (sumTotal[property] / grandTotal * 100).toFixed(2);
        }

        return percentages;
    };

    useEffect(() => {
        const ctx = document.getElementById('largepiechartsummary').getContext('2d');
        if (largepiechartRef.current) {
            largepiechartRef.current.destroy();
        }

        if (geojson) {
            const percentages = calculatePercentages(geojson);
            const labels = Object.keys(percentages);
            const dataValues = Object.values(percentages);

            largepiechartRef.current = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: labels,
                    datasets: [{
                        data: dataValues,
                        backgroundColor: isUKHabColoursActive ? 'transparent' : labels.map(label => presetColors[label]),
                        hoverOffset: 0
                    }]
                },
                options: {
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false, // Disable tooltips
                        },
                    },
                    hover: {
                        mode: null
                    },
                    elements: {
                        arc: {
                            borderColor: 'rgba(0, 0, 0, 0)' // or 'transparent'
                        }
                    },
                    animation: false,
                    onHover: (event, chartElement) => {
                        // Disable any hover effect by making this function empty or you could explicitly disable pointer interactions
                        event.native.target.style.cursor = chartElement[0] ? 'default' : 'default';
                    }
                }
            });

            if (isUKHabColoursActive) {
                const backgroundColors = [];
                const imageLoadPromises = labels.map((label, index) => new Promise((resolve, reject) => {
                    const image = new Image();
                    image.src = `${process.env.PUBLIC_URL}/ukhab/${label}.png`;
                    image.onload = () => {
                        const pattern = ctx.createPattern(image, 'repeat');
                        backgroundColors[index] = pattern;
                        pattern.setTransform(new DOMMatrix().scale(0.1));
                        resolve();
                    };
                    image.onerror = reject;
                }));

                Promise.all(imageLoadPromises).then(() => {
                    largepiechartRef.current.data.datasets[0].backgroundColor = backgroundColors;
                    largepiechartRef.current.update();
                }).catch(error => {
                    console.error("Error loading images:", error);
                });
            }
        }

        return () => {
            if (largepiechartRef.current) {
                largepiechartRef.current.destroy();
            }
        };
    }, [geojson, isViewingPieChart, isUKHabColoursActive, selectedMetric, selectedYearEditing]);

    const hexToRgb = hex => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return { r, g, b };
    };

    const rgbToGrey = ({ r, g, b }) => {
        // Average method for greyscale
        const grey = parseInt((r + g + b) / 3);
        return `rgb(${grey}, ${grey}, ${grey})`;
    };

    const handleLandTypeClick = (key) => {
        const chart = largepiechartRef.current;

        if (key === 'reset') {
            // Reset colors to their original state
            chart.data.datasets[0].backgroundColor = chart.data.labels.map(label => presetColors[label]);
            // Remove any active elements
            chart.setActiveElements([]);
            // Update the chart
            chart.update();
            // Reset selected land type state
            setSelectedLandType(null);
            polygonCustomLayerRef.current.adjustFeatureOpacitiesPerUKHAB(key);
            return; // Exit the function early
        }

        const activeElementIndex = chart.data.labels.indexOf(key);
        // Set all datasets to be visible initially
        chart.setDatasetVisibility(0, true);
        // Set the opacity for inactive segments
        chart.setDatasetVisibility(0, index => index !== activeElementIndex, { opacity: 0.1 });
        // Set the active element
        chart.setActiveElements([{ datasetIndex: 0, index: activeElementIndex }]);
        // Update the chart
        chart.update();
        polygonCustomLayerRef.current.adjustFeatureOpacitiesPerUKHAB(key);
        const backgroundColors = [];
        const labels = chart.data.labels;
        labels.forEach(label => {
            if (label === key) {
                backgroundColors.push(presetColors[key]); // Use original color for selected item
            } else {
                // Convert the original color to greyscale for non-selected items
                const originalColor = presetColors[label];
                const rgbColor = hexToRgb(originalColor);
                const greyColor = rgbToGrey(rgbColor);
                backgroundColors.push(greyColor);
            }
        });
        chart.data.datasets[0].backgroundColor = backgroundColors;
        chart.update();
        setSelectedLandType(key);
        setSelectedLandTypeCapitalised(key.charAt(0).toUpperCase() + key.slice(1));
    };

    // const handleResetPieButtonClick = () => {
    //     const chart = largepiechartRef.current;
    //     chart.data.datasets[0].backgroundColor = isUKHabColoursActive ? 'transparent' : chart.data.labels.map(label => presetColors[label]);
    //     chart.update();
    //     setSelectedLandType(null);
    // }

    return (
        <div className="LargepieChartWindow">
            <div className="closelargePieButton" onClick={handleClosePieButtonClick}>
                <IoClose />
            </div>
            <h2 className="largePieChartTitle">Pie Chart</h2>
            <canvas id="largepiechartsummary" className="largepieChart" />
            <h2 className="largePieChartLandTypesTitle">Land Type Key</h2>
            <div onMouseOut={() => handleLandTypeClick('reset')} className="largeland_types_accum">
                {Object.entries(calculatePercentages(geojson))
                    .sort(([, valueA], [, valueB]) => valueB - valueA) // Sort by value in descending order
                    .map(([key, value]) => {
                        const imageSource = `${process.env.PUBLIC_URL}/ukhab/${key}.png`;
                        const capitalisedKey = key.charAt(0).toUpperCase() + key.slice(1);
                        return (
                            <div
                                key={key}
                                className={`land_type_item ${selectedLandType === key ? 'selected' : ''}`}
                                onMouseMove={() => handleLandTypeClick(key)}
                            >
                                {isUKHabColoursActive ? (
                                    <img className="ukhabcolorcircle" src={imageSource} alt={key} />
                                ) : (
                                    <span className="color_circle" style={{ backgroundColor: presetColors[key] }}></span>
                                )}
                                <div className="land_type_item_textLargePie">
                                    <p className="land_type_item_key">{capitalisedKey.length > 22 ? `${capitalisedKey.substring(0, 22)}...` : capitalisedKey}</p>
                                    <p className="land_type_item_value_large">{value}%</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
            {/* <div className="resetLargePieButton" onClick={handleResetPieButtonClick}>
                <button>Reset</button>
            </div> */}
            {selectedLandType && (
                <div className="selectedLandTypeInfo">
                    <h4>Highlighted Land Type</h4>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isUKHabColoursActive ? (
                            <img className="ukhabcolorcircle" src={`${process.env.PUBLIC_URL}/ukhab/${selectedLandType}.png`} alt={selectedLandType} />
                        ) : (
                            <span className="color_circle" style={{ backgroundColor: presetColors[selectedLandType] }}></span>
                        )}
                        <h2 className="selectedLandTypeTitle">{selectedLandTypeCapitalised}</h2>
                    </div>
                    <p className="selectedLandTypeDescription">
                        {largePieChartBio.habitats.find(habitat => habitat.name === selectedLandType)?.description}
                    </p>
                </div>
            )}
        </div>
    );
};

export default LargePieChart;
