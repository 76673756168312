import React, { useEffect, useState } from 'react';

const MetricDropdown = ({
  metricOptions,
  resetMetric,
  setSelectedMetric,
  selectedMetric,
  globalState,
  publicMetricOptions,
  ResetMapYear,
  globalRefs,
  ResetMapMetric,
  ResetMapMetricPublic
}) => {

  const [clicked, setClicked] = useState(false);
  const [dropdownMetric, setDropdownMetric] = useState('Select Metric');

  let loggedInToAPI = globalState.loggedInToAPI;

  const handleOptionClick = (selectedValue) => {
    setSelectedMetric(selectedValue);
    setClicked(true);
  };

  useEffect(() => {
    if (clicked) {
      if (loggedInToAPI) {
        ResetMapMetric(globalRefs, globalState);
      }
      else {
        ResetMapMetricPublic(globalRefs, globalState);
      }
      setClicked(false);
      console.log("Resetting metric in metric dropdown");
    }
  }, [selectedMetric, clicked]);

  let publicMetricOptionsReplacements = [
    {
      value: 'carbon_density',
      label: 'Total CO2 Emissions Produced'
    },
    {
      value: 'co2trains',
      label: 'Total CO2 Emissions By Trains'
    },
    {
      value: 'co2fuelstations',
      label: 'Total CO2 Emissions By Fuel Stations'
    },
    {
      value: 'co2buildings',
      label: 'Total CO2 Emissions By Buildings'
    },
    {
      value: 'co2airports',
      label: 'Total CO2 Emissions By Airports'
    },
    {
      value: 'co2farms',
      label: 'Total CO2 Emissions By Farms'
    },
    {
      value: 'co2refineries',
      label: 'Total CO2 Emissions By Refineries'
    }
  ];

  let privateMetricOptionsReplacements = [
    {
      value: 'ParcelCarbon',
      label: 'Carbon Sequestration'
    },
    {
      value: 'ParcelCarbonFlux',
      label: 'Carbon Flux'
    },
    {
      value: 'Value_GBP',
      label: 'Value in GBP'
    },
    {
      value: 'BDUnits_Parcel',
      label: 'Biodiversity Units'
    }
  ]

  if (!loggedInToAPI) {
    publicMetricOptions = publicMetricOptionsReplacements;
  }

  if (loggedInToAPI) {
    metricOptions = privateMetricOptionsReplacements;
  }

  return (
    <div className='select-container'>
      {loggedInToAPI ? (
        <>
          <option value="" disabled>Select Metric</option>
          <select className='metricSelectionModify' onChange={(e) => handleOptionClick(e.target.value)}>
            {metricOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="select-arrow"></div>
        </>
      ) : (
        <select
          className='metricSelectionModify'
          onChange={(e) => handleOptionClick(e.target.value)}
          defaultValue=""
        // Optional: to set the default value if needed
        >
          {/* <option value="" disabled>Select Metric</option> */}
          {publicMetricOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )
      }
    </div >
  );
};

export default MetricDropdown;


