import React, { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { presetColors } from '../../libs/colours.js';

const ClickedInfo = ({
  isVisible,
  polygonCustomLayerRef,
  clickedInfo,
  clickedInfoHeader,
  clickedInfoHeaderMobile,
  globalState,
  foundFeature,
  longstandingGeoJSONRef
}) => {
  // State to manage the visibility of the more information section
  const [showMore, setShowMore] = useState(false);

  let selectedMetric = globalState.selectedMetric;
  let selectedYearEditing = globalState.selectedYearEditing;

  let acknowledgeNewFaetureNewDepth = globalState.acknowledgeNewFaetureNewDepth;
  let loggedInToAPI = globalState.loggedInToAPI;
  const mobileStatus = isMobile ? "mobile" : "not-mobile";

  // clickedInfoHeader

  const [modalValue, setModalValue] = useState(null);
  const [modalMetric, setModalMetric] = useState(null);
  const [modalOther, setModalOther] = useState(null);
  const [modalHabitat, setModalHabitat] = useState(null);
  const [modalSkylarkId, setModalSkylarkId] = useState(null);
  const [unitValue, setUnitValue] = useState(null);
  const [preUnit, setPreUnit] = useState(null);

  const [clickedColor, setClickedColor] = useState(null);

  const geojson = longstandingGeoJSONRef.current;

  let clickedProperties = null;
  let clickedHabitat = null;

  function roundToTwoDecimals(value) {
    if (typeof value === 'number') {
      return Math.round(value * 100) / 100;
    }
    return value;
  }

  function formatFeatureData(featureData) {
    delete featureData.categorized;
    console.log(featureData)
    return (
      <table className='clickedInfoTable'>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(featureData).map(([key, value]) => (
            <tr key={key}>
              <td className="clickedInfoLeftHandColumn">{key}:</td>
              <td className="clickedInfoRightHandColumn">{roundToTwoDecimals(value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  useEffect(() => {
    if (clickedInfoHeader !== null) {
      for (let i = 0; i < geojson.features.length; i++) {
        if (geojson.features[i].skylark_id === clickedInfoHeader) {
          let selectedlandtype = geojson.features[i].feature_data_simple_timeseries.HabitatType[selectedYearEditing];
          let color = presetColors[selectedlandtype];
          setClickedColor(color);
          if (geojson.features[i].feature_data_simple_timeseries[selectedMetric] && geojson.features[i].feature_data_simple_timeseries[selectedMetric][selectedYearEditing]) {
            clickedProperties = geojson.features[i].feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
          } else {
            clickedProperties = geojson.features[i].feature_data_simple_timeseries[selectedMetric];
          }
          if (geojson.features[i].feature_data_simple_timeseries.HabitatType[selectedYearEditing] && geojson.features[i].feature_data_simple_timeseries.HabitatType[selectedYearEditing] !== "undefined" && geojson.features[i].feature_data_simple_timeseries.HabitatType[selectedYearEditing] !== "null") {
            clickedHabitat = geojson.features[i].feature_data_simple_timeseries.HabitatType[selectedYearEditing];
          } else {
            clickedHabitat = geojson.features[i].feature_data_simple_timeseries.HabitatType;
          }
          console.log(clickedHabitat)
          clickedProperties = Math.round(clickedProperties);
          setModalValue(JSON.stringify(clickedProperties, null, 2));
          let featureDataSimple = geojson.features[i].feature_data_simple;
          console.log(featureDataSimple)
          let formattedData = formatFeatureData(featureDataSimple);
          setModalOther(formattedData);
          const truncatedClickedHabitat = clickedHabitat.substring(0, 25);
          if (truncatedClickedHabitat.length < clickedHabitat.length) {
            setModalHabitat(truncatedClickedHabitat + '...');
          } else {
            setModalHabitat(truncatedClickedHabitat);
          }
          const truncatedSkylarkId = geojson.features[i].skylark_id.substring(0, 15);
          setModalSkylarkId(truncatedSkylarkId + '...');
        }
      }
    }
    console.log(loggedInToAPI)
    if (selectedMetric === "carbon_density" && !loggedInToAPI) {
      setModalMetric('Total CO2 Emissions Produced:')
      setUnitValue('tC/ha/yr')
      setPreUnit('')
    }
    else if (selectedMetric === "co2trains") {
      setModalMetric('Total CO2 Emissions By Trains:')
      setUnitValue('tC/ha/yr')
      setPreUnit('')
    }
    else if (selectedMetric === "co2fuelstations") {
      setModalMetric('Total CO2 Emissions By Fuel Stations:')
      setUnitValue('tC/ha/yr')
      setPreUnit('')
    }
    else if (selectedMetric === "co2buildings") {
      setModalMetric('Total CO2 Emissions By Buildings:')
      setUnitValue('tC/ha/yr')
      setPreUnit('')
    }
    else if (selectedMetric === "co2airports") {
      setModalMetric('Total CO2 Emissions By Airports:')
      setUnitValue('tC/ha/yr')
      setPreUnit('')
    }
    else if (selectedMetric === "co2farms") {
      setModalMetric('Total CO2 Emissions By Farms:')
      setUnitValue('tC/ha/yr')
      setPreUnit('')
    }
    else if (selectedMetric === "co2refineries") {
      setModalMetric('Total CO2 Emissions By Refineries:')
      setUnitValue('tC/ha/yr')
      setPreUnit('')
    }
    else if (selectedMetric === "ParcelCarbon" && loggedInToAPI) {
      setModalMetric('Carbon Sequestration:')
      setUnitValue('tC/Parcel')
      setPreUnit('')
    }
    else if (selectedMetric === "ParcelCarbon" && !loggedInToAPI) {
      setModalMetric('CO2 Produced Total:')
      setUnitValue('tC/Parcel')
      setPreUnit('')
    }
    else if (selectedMetric === "ParcelCarbonFlux") {
      setModalMetric('Carbon Flux:')
      setUnitValue('tCO2e/yr')
      setPreUnit('')
    }
    else if (selectedMetric === "Value_GBP") {
      setModalMetric('Value in GBP:')
      setUnitValue('')
      setPreUnit('£')
    }
    else if (selectedMetric === "BDUnits_Parcel") {
      setModalMetric('Biodiversity Units:')
      setUnitValue('Bio Units')
      setPreUnit('')
    }
  }, [clickedInfoHeader, selectedMetric, selectedYearEditing, acknowledgeNewFaetureNewDepth]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return ''; // Handle empty or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const capitalizedModalHabitat = capitalizeFirstLetter(modalHabitat);

  return (
    isVisible && (
      <div className={`clicked_info ${mobileStatus}`}>
        <MobileView>
          <IoClose className="clicked_info_close_icon_mobile" size={20} onClick={() => polygonCustomLayerRef.current.handleCloseClickedInfoCancel()} />
          {clickedInfoHeader !== null && (
            <div className='clicked_info_inner'>
              <div className='clickedInfo1'>
                <p className='clicked_info_innerTitle'>
                  Land type:
                </p>
                <p className='clicked_info_innerHabitat'>
                  {capitalizedModalHabitat}
                </p>
              </div>
              {/* <div className='clickedInfo2'>
                <p className='clicked_info_parcelRefTitle'>
                  Parcel Reference:
                </p>
                <p className='clicked_info_parcelRef'>
                  {modalSkylarkId}
                </p>
              </div> */}
              <div className='clickedInfo3'>
                <div className='clicked_info_metric'>
                  <p>
                    {modalMetric}:
                  </p>
                </div>
                <div className='clicked_info_value'>
                  <p>
                    {modalValue}
                  </p>
                </div>
              </div>
            </div>
          )}
        </MobileView>
        <BrowserView>
          <div className='clicked_info_header'>
            <div className='clicked_info_close'>
              <IoClose className="clicked_info_close_icon" size={20} onClick={() => polygonCustomLayerRef.current.handleCloseClickedInfoCancel()} />
            </div>
          </div>
          {clickedInfoHeader !== null && (
            <div className='clicked_info_inner'>
              <h2>Parcel Information</h2>
              <div class="divider"></div>
              <div className='clickedInfo1'>
                <p className='clicked_info_innerTitle'>
                  Land type:
                </p>
                <div className="landtypeColorIcon" style={{ backgroundColor: clickedColor }}></div>
                <p className='clicked_info_innerHabitat'>
                  {capitalizedModalHabitat}
                </p>
              </div>
              {/* <div className='clickedInfo2'>
                <p className='clicked_info_parcelRefTitle'>
                  Parcel Reference:
                </p>
                <p className='clicked_info_parcelRef'>
                  {modalSkylarkId}
                </p>
              </div> */}
              <div class="divider"></div>
              <div className='clickedInfo3'>
                <div className='clicked_info_metric'>
                  <p>
                    {modalMetric}
                  </p>
                </div>
                <div className='clicked_info_value'>
                  <p>
                    {preUnit} {modalValue} {unitValue}
                  </p>
                </div>
              </div>
              <div class="divider"></div>
            </div>
          )}
          <div
            className='clicked_info_more_button'
            onClick={() => setShowMore(!showMore)} // Toggle the showMore state
          >
            {showMore ? "Show Less" : "Show More"}
          </div>
          {showMore && (
            <div className='clicked_info_inner'>
              <div className='clicked_info_other'>
                {modalOther}
              </div>
            </div>
          )}
        </BrowserView>
      </div>
    )
  );
};

export default ClickedInfo;
