import React, { useEffect } from 'react';
import { useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import SaveOptions from './topSubMenuComponents/saveOptions.js';
import SavedStatus from './topSubMenuComponents/savedStatus.js';
import SearchBar from './topSubMenuComponents/SearchBar.js';
import Geolocate from './topSubMenuComponents/geolocate.js';
import Compass from './topSubMenuComponents/compass.js';

const TopSubMenu = ({
    openFilenameInputModal,
    saveCurrentDataSetClick,
    loadMenuClick,
    isHasSaved,
    savedFilename,
    isHasSavedCurrentData,
    loadedFileName,
    isFilenameInputOpen,
    closeFilenameInputModal,
    newFilename,
    handleFilenameInputChange,
    saveDataWithFilenameClick,
    isMenuLoadClick,
    closeLoadMenu,
    filenames,
    handleItemClick,
    mapboxTokenRef,
    globalState,
    globalRefs,
    openAboutPanel,
    openPrivacyPanel,
    openTacPanel,
    openInverstorsPanel,
    isInAboutPanel,
    isInInvestorsPanel,
    isInPrivacyPanel,
    isInTacPanel,
    resetAboutPanels,
    isInSettings,
    mapbearing,
    loggedInToAPI
}) => {

    const [totalSumMetric, setTotalSumMetric] = useState(0);
    const [unitValue, setUnitValue] = useState('');
    const [preUnit, setPreUnit] = useState('');
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [topSubMenuDisplayMetric, setTopSubMenuDisplayMetric] = useState('Select Metric');
    const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);
    let selectedMetric = globalState.selectedMetric;
    let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
    let selectedYearEditing = globalState.selectedYearEditing;
    let mapLoaded = globalState.mapLoaded;

    const backToMap = () => {
        resetAboutPanels();
    }

    useEffect(() => {
        if (selectedMetric === "carbon_density" && !loggedInToAPI) {
            setTopSubMenuDisplayMetric('Total CO2 Emissions Produced')
        }
        else if (selectedMetric === "co2trains") {
            setTopSubMenuDisplayMetric('Total CO2 Emissions By Trains')
        }
        else if (selectedMetric === "co2fuelstations") {
            setTopSubMenuDisplayMetric('Total CO2 Emissions By Fuel Stations')
        }
        else if (selectedMetric === "co2buildings") {
            setTopSubMenuDisplayMetric('Total CO2 Emissions By Buildings')
        }
        else if (selectedMetric === "co2airports") {
            setTopSubMenuDisplayMetric('Total CO2 Emissions By Airports')
        }
        else if (selectedMetric === "co2farms") {
            setTopSubMenuDisplayMetric('Total CO2 Emissions By Farms')
        }
        else if (selectedMetric === "co2refineries") {
            setTopSubMenuDisplayMetric('Total CO2 Emissions By Refineries')
        }
        else if (selectedMetric === "ParcelCarbon" && loggedInToAPI) {
            setTopSubMenuDisplayMetric('Carbon Sequestration Levels')
        }
        else if (selectedMetric === "ParcelCarbon" && !loggedInToAPI) {
            setTopSubMenuDisplayMetric('Total CO2 Emissions Produced')
        }
        else if (selectedMetric === "ParcelCarbonFlux") {
            setTopSubMenuDisplayMetric('Levels of Carbon Flux')
        }
        else if (selectedMetric === "Value_GBP") {
            setTopSubMenuDisplayMetric('Parcel Value in GBP')
        }
        else if (selectedMetric === "BDUnits_Parcel") {
            setTopSubMenuDisplayMetric('Biodiversity Units')
        }
    }, [selectedMetric, loggedInToAPI]);

    let totalSum = 0;

    useEffect(() => {

        if (selectedMetric === "carbon_density" && !loggedInToAPI) {
            setUnitValue('tC/ha/yr')
            setPreUnit('')
        }
        else if (selectedMetric === "co2trains") {
            setUnitValue('tC/ha/yr')
            setPreUnit('')
        }
        else if (selectedMetric === "co2fuelstations") {
            setUnitValue('tC/ha/yr')
            setPreUnit('')
        }
        else if (selectedMetric === "co2buildings") {
            setUnitValue('tC/ha/yr')
            setPreUnit('')
        }
        else if (selectedMetric === "co2airports") {
            setUnitValue('tC/ha/yr')
            setPreUnit('')
        }
        else if (selectedMetric === "co2farms") {
            setUnitValue('tC/ha/yr')
            setPreUnit('')
        }
        else if (selectedMetric === "co2refineries") {
            setUnitValue('tC/ha/yr')
            setPreUnit('')
        }
        else if (selectedMetric === "ParcelCarbon" && loggedInToAPI) {
            setUnitValue('tC/Parcel')
            setPreUnit('')
        }
        else if (selectedMetric === "ParcelCarbon" && !loggedInToAPI) {
            setUnitValue('tC/Parcel')
            setPreUnit('')
        }
        else if (selectedMetric === "ParcelCarbonFlux") {
            setUnitValue('tCO2e/yr')
            setPreUnit('')
        }
        else if (selectedMetric === "Value_GBP") {
            setUnitValue('')
            setPreUnit('£')
        }
        else if (selectedMetric === "BDUnits_Parcel") {
            setUnitValue('Bio Units')
            setPreUnit('')
        }

        console.log(longstandingGeoJSONRef.current)
        function check() {
            if (longstandingGeoJSONRef.current) {
                console.log(longstandingGeoJSONRef.current)
                for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
                    let metricValue;
                    if (longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries[selectedMetric] !== undefined) {
                        metricValue = longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
                        if (metricValue !== undefined) {
                            totalSum += metricValue;
                        }
                    }
                }
            }
            console.log("Total Sum of the selected metric:", totalSum);
            setTotalSumMetric(totalSum);
        }
        check();
    }, [selectedMetric, longstandingGeoJSONRef.current, selectedYearEditing]);

    return (
        <div className={`top_Submenu ${isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? 'inabout' : ''}`}>

            <BrowserView>

                {isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? (
                    <div className='topSubmenuBack'>
                        <button className='topSubmenuBackButton' onClick={backToMap}>Back to Map</button>
                    </div>
                ) :
                    <div>
                        <SaveOptions
                            openFilenameInputModal={openFilenameInputModal}
                            saveCurrentDataSetClick={saveCurrentDataSetClick}
                            loadMenuClick={loadMenuClick}
                            loggedInToAPI={loggedInToAPI}
                        />

                        <SavedStatus
                            isHasSaved={isHasSaved}
                            savedFilename={savedFilename}
                            isHasSavedCurrentData={isHasSavedCurrentData}
                            loadedFileName={loadedFileName}
                        />

                        <SearchBar
                            mapboxTokenRef={mapboxTokenRef}
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Geolocate
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Compass
                            globalRefs={globalRefs}
                            globalState={globalState}
                            mapbearing={mapbearing}
                        />

                        <div className='top_Submenu_metricDisplay'>
                            <p className='top_Submenu_metricDisplayMetricTitle'>Metric Total: </p>
                            <p className='top_Submenu_metricDisplayMetric'>
                                {preUnit} {totalSumMetric.toFixed(2)} {unitValue}
                            </p>
                        </div>

                        <div className='top_Submenu_metricTotalsDisplay'>
                            <p className='top_Submenu_metricTotalsDisplayMetricTitle'>Metric Selected: </p>
                            <p className='top_Submenu_metricTotalsDisplayMetric'>
                                {topSubMenuDisplayMetric} </p>
                        </div>

                        <div className='top_Submenu__right'>
                            <div className='top_Submenu__right__menu'>
                                <div className='top_Submenu__right__menu__load'>
                                    {/* <p>Create your own data map with Skylark</p> */}
                                    {/* <button
                                        className='top_Submenu__right__menu__load__button'
                                    >
                                        Find out more
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </BrowserView>
            <MobileView>

                {isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? (
                    <div className='topSubmenuBack'>
                        <button className='topSubmenuBackButton' onClick={resetAboutPanels}>Back to Map</button>
                    </div>
                ) : (
                    <div>
                        {/* <SaveOptions
                                openFilenameInputModal={openFilenameInputModal}
                                saveCurrentDataSetClick={saveCurrentDataSetClick}
                                loadMenuClick={loadMenuClick}
                            /> */}

                        {/* <SavedStatus
                                isHasSaved={isHasSaved}
                                savedFilename={savedFilename}
                                isHasSavedCurrentData={isHasSavedCurrentData}
                                loadedFileName={loadedFileName}
                            /> */}

                        <SearchBar
                            mapboxTokenRef={mapboxTokenRef}
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Geolocate
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Compass
                            globalRefs={globalRefs}
                            globalState={globalState}
                            mapbearing={mapbearing}
                        />
                    </div>
                )}

            </MobileView>

        </div >

    );
};

export default TopSubMenu;